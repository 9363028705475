// Odkaz  s tridou 'popup' se otevre do noveho okna
// data-width a data-height nastavi vysku a sirku otevreneho okna
( function() {

	var $ = window.jQuery;

	$( "a.popup" ).click( function( ) {
		var element = $( this );
		var url = $( this ).attr( "href" );

		var defaultOptions = {
			width: element.data( "width" ) || 400,
			height: element.data( "height" ) || 300
		};

		window.open( url, "_blank",
			"width=" + defaultOptions.width + ",height=" + defaultOptions.height );

		return false;
	} );

} )();
