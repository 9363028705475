( function() {

	  var $ = window.jQuery;
	  if ( !window.ATK14COMMON ) {
		    window.ATK14COMMON = {};
	  }
	  var ATK14COMMON = window.ATK14COMMON;

		ATK14COMMON.Photoswipe = function( element ) {
			this.element = element;
			this.$element = $( element );
			var i = 0;
			var psw = this;

			this.data = this.$element.find( ".js--photoswipe-image" ).map(
				function() {
					var $e = $( this );
					var index = i;
					i += 1;
					$e.click( function( event ) {
						event.preventDefault();
						var photoswipe = new window.PhotoSwipe(
								psw.galleryElement,
								window.PhotoSwipeUI_Default,
								psw.data,
								{
									index: index,
									getThumbBoundsFn: function( index ) {
										var thumbnail = psw.data[ index ].element
															.getElementsByTagName( "img" )[ 0 ],
												pageYScroll = window.pageYOffset ||
																document.documentElement.scrollTop,
												rect = thumbnail.getBoundingClientRect();
										return { x:rect.left, y:rect.top + pageYScroll,
														 w:rect.width };
									}
								}
						);
						photoswipe.init();
						return false;
					} );
					var data = $e.data( "photoswipe" );
					if ( !data.title ) {
						var t = $e.find( ".js--photoswipe-title" );
						if ( t.length ) {
								data.title = $( t ).html();
						}
					}
					data.element = this;
					return data;
			} );

			this.galleryElement = $( "#photoswipe-root-element" )[ 0 ];
		};

		ATK14COMMON.Photoswipe.init = function() {
			$( ".js--photoswipe" ).each(
				function( index, element ) { new ATK14COMMON.Photoswipe( element ); }
			);
		};

		$( ATK14COMMON.Photoswipe.init );

} )();
