( function( window, undefined ) {
	"use strict";

	var $ = window.jQuery;
	if ( !window.DLMOBILE ) {
		window.DLMOBILE = {};
	}

	var DLMOBILE = window.DLMOBILE;

	/*
	* Testing media queries with jQuery
	* We need to turn it into a function.
	* To apply the changes both on document ready and when we resize the browser.
	*/

	DLMOBILE.isSmallDevice = function() {
		var isSmallDevice = false;

		// Set the matchMedia
		if ( window.matchMedia( "(max-width: 991px)" ).matches ) {
			console.log( "isSmallDevice: " + $( window ).width() );
			isSmallDevice = true;
		}

		return isSmallDevice;
	};

	// Call the function
	DLMOBILE.isSmallDevice();

	// Attach the function to the resize event listener
	window.addEventListener( "resize", DLMOBILE.isSmallDevice, false );

} )( window, window.jQuery );
