window.UTILS = window.UTILS || { };

// Initializing Markdown editor with a custom parser
window.UTILS.initializeMarkdownEditor = function() {
	$( "textarea[data-provide=markdown]" ).markdown( {

		// Z toolbaru v editoru odstranime nejake ikonky...
		onShow: function( e ) {
			e.hideButtons( [ "Image", "Code" ] );
		},
		language: $( "html" ).attr( "lang" ), // "cs", "en", ...
		resize: "vertical",
		parser: function( source ) {
			$.ajax( {
				type: "POST",
				url: "/api/cs/markdown/transform/",
				data: {
					source: source
				},
				success: function( output ) {
					$( "#md-preview-replacement" ).replaceWith( output );
				}
			} );
			return "<i id=\"md-preview-replacement\"" +
				" class=\"glyphicon glyphicon-time\"></i>";
		}
	} );
};

// Usage: window.UTILS.livePreview( "id_body" );
window.UTILS._lastSourcePreviewed = "";
window.UTILS.livePreview = function( elementId ) {
	var _elementId = "#" + elementId,
		source = $( _elementId ).val();

	if ( source !== window.UTILS._lastSourcePreviewed ) {

		$.ajax( {
			type: "POST",
			url: "/api/cs/markdown/transform/",
			data: {
				source: source
			},
			success: function( output ) {
				var previewHeight, previewScrollHeight, srcHeight,
					srcScrollTop, srcScrollHeight, srcMiddle, newTop;

				$( "#preview" ).html( output );

				srcHeight = $( _elementId ).innerHeight();
				srcScrollTop = $( _elementId ).scrollTop();
				srcScrollHeight = $( _elementId )[ 0 ].scrollHeight;
				previewHeight = $( "#preview" ).innerHeight();
				previewScrollHeight = $( "#preview" )[ 0 ].scrollHeight;

				srcMiddle = ( srcScrollTop + ( srcHeight / 2.0 ) ) / srcScrollHeight;

				newTop = previewScrollHeight * srcMiddle - ( previewHeight / 2.0 );

				$( "#preview" ).scrollTop( newTop );
			}
		} );
		window.UTILS._lastSourcePreviewed = source;
	}

	setTimeout( function() { window.UTILS.livePreview( elementId ); }, 1000 );
};

// Toto je opsano z http://photoswipe.com/documentation/getting-started.html
// z Example on CodePen (First gallery, Second gallery)
window.UTILS.initPhotoSwipeFromDOM = function( gallerySelector ) {

		// Parse slide data (url, title, size ...) from DOM elements
		// (children of gallerySelector)
		var parseThumbnailElements = function( el ) {
				var thumbElements = el.childNodes,
						numNodes = thumbElements.length,
						items = [],
						figureEl,
						linkEl,
						size,
						item;

				for ( var i = 0; i < numNodes; i++ ) {

						figureEl = thumbElements[ i ]; // <figure> element

						// Include only element nodes
						if ( figureEl.nodeType !== 1 ) {
								continue;
						}

						linkEl = figureEl.children[ 0 ]; // <a> element

						size = linkEl.getAttribute( "data-size" ).split( "x" );

						// Create slide object
						item = {
								src: linkEl.getAttribute( "href" ),
								w: parseInt( size[ 0 ], 10 ),
								h: parseInt( size[ 1 ], 10 )
						};

						if ( figureEl.children.length > 1 ) {

								// <figcaption> content
								item.title = figureEl.children[ 1 ].innerHTML;
								item.description = "description";
						}

						if ( linkEl.children.length > 0 ) {

								// <img> thumbnail element, retrieving thumbnail url
								item.msrc = linkEl.children[ 0 ].getAttribute( "src" );
						}

						// Save link to element for getThumbBoundsFn
						item.el = figureEl;
						items.push( item );
				}

				return items;
		};

		// Find nearest parent element
		var closest = function closest( el, fn ) {
				return el && ( fn( el ) ? el : closest( el.parentNode, fn ) );
		};

		// Triggers when user clicks on thumbnail
		var onThumbnailsClick = function( e ) {
				e = e || window.event;
				e.preventDefault ? e.preventDefault() : e.returnValue = false;

				var eTarget = e.target || e.srcElement;

				// Find root element of slide
				var clickedListItem = closest( eTarget, function( el ) {
						return ( el.tagName && el.tagName.toUpperCase() === "FIGURE" );
				} );

				if ( !clickedListItem ) {
						return;
				}

				// Find index of clicked item by looping through all child nodes
				// alternatively, you may define index via data- attribute
				var clickedGallery = clickedListItem.parentNode,
						childNodes = clickedListItem.parentNode.childNodes,
						numChildNodes = childNodes.length,
						nodeIndex = 0,
						index;

				for ( var i = 0; i < numChildNodes; i++ ) {
						if ( childNodes[ i ].nodeType !== 1 ) {
								continue;
						}

						if ( childNodes[ i ] === clickedListItem ) {
								index = nodeIndex;
								break;
						}
						nodeIndex++;
				}

				if ( index >= 0 ) {

						// Open PhotoSwipe if valid index found
						openPhotoSwipe( index, clickedGallery );
				}
				return false;
		};

		// Parse picture index and gallery index from URL (#&pid=1&gid=2)
		var photoswipeParseHash = function() {
				var hash = window.location.hash.substring( 1 ),
				params = {};

				if ( hash.length < 5 ) {
						return params;
				}

				var vars = hash.split( "&" );
				for ( var i = 0; i < vars.length; i++ ) {
						if ( !vars[ i ] ) {
								continue;
						}
						var pair = vars[ i ].split( "=" );
						if ( pair.length < 2 ) {
								continue;
						}
						params[ pair[ 0 ] ] = pair[ 1 ];
				}

				if ( params.gid ) {
						params.gid = parseInt( params.gid, 10 );
				}

				return params;
		};

		var openPhotoSwipe = function( index, galleryElement, disableAnimation, fromURL ) {
				var PhotoSwipe = window.PhotoSwipe,
					PhotoSwipeUIDefault = window.PhotoSwipeUI_Default,
					pswpElement = document.querySelectorAll( ".pswp" )[ 0 ],
					gallery,
					options,
					items;

				items = parseThumbnailElements( galleryElement );

				// Define options (if needed)
				options = {

						// Define gallery index (for URL)
						galleryUID: galleryElement.getAttribute( "data-pswp-uid" ),

						getThumbBoundsFn: function( index ) {

								// See Options -> getThumbBoundsFn section
								// of documentation for more info
								var thumbnail =
										items[ index ].el.getElementsByTagName( "img" )[ 0 ],
										pageYScroll = window.pageYOffset ||
											document.documentElement.scrollTop,
										rect = thumbnail.getBoundingClientRect();

								return { x:rect.left, y:rect.top + pageYScroll, w:rect.width };
						}

				};

				// PhotoSwipe opened from URL
				if ( fromURL ) {
						if ( options.galleryPIDs ) {

								// Parse real index when custom PIDs are used
								// http://photoswipe.com/documentation/faq.html#custom-pid-in-url
								for ( var j = 0; j < items.length; j++ ) {
										if ( items[ j ].pid === index ) {
												options.index = j;
												break;
										}
								}
						} else {

								// In URL indexes start from 1
								options.index = parseInt( index, 10 ) - 1;
						}
				} else {
						options.index = parseInt( index, 10 );
				}

				// Exit if index not found
				if ( isNaN( options.index ) ) {
						return;
				}

				if ( disableAnimation ) {
						options.showAnimationDuration = 0;
				}

				// Pass data to PhotoSwipe and initialize it
				// TODO: Ty uvozovky kolem PhotoSwipe ??
				gallery = new PhotoSwipe( pswpElement, PhotoSwipeUIDefault,
					items, options );
				gallery.init();
		};

		// Loop through all gallery elements and bind events
		var galleryElements = document.querySelectorAll( gallerySelector );

		for ( var i = 0, l = galleryElements.length; i < l; i++ ) {
				galleryElements[ i ].setAttribute( "data-pswp-uid", i + 1 );
				galleryElements[ i ].onclick = onThumbnailsClick;
		}

		// Parse URL and open gallery if it contains #&pid=3&gid=1
		var hashData = photoswipeParseHash();
		if ( hashData.pid && hashData.gid ) {
				openPhotoSwipe( hashData.pid, galleryElements[ hashData.gid - 1 ], true, true );
		}

};

window.UTILS.handleSuggestions = function() {

				// Naseptavani cehokoli
				$( "[data-suggesting='yes']" ).autocomplete( {
					source: function( request, response ) {
						var $el = this.element,
							url = $el.data( "suggesting_url" ),
							term;
						term = request.term;

						$.getJSON( url, { q: term }, function( data ) {
							response( data );
						} );
					}
				} );

				//Naseptavani multiple-checkboxu
				var handler = function( e ) {
						var $t =  $( e.target );
						if ( e.target.lastQuery === $t.val() ) {
							return;
						}
						e.target.lastQuery = $t.val();

						var $el = $t.parent( ".js--suggestion-multiple" );
						var data = {};
						$el.find( "input:checkbox:checked" ).each( function() {
							data[ "exclude[" + this.value  + "]" ] = this.value;
						} );

						$.ajax( {
							dataType: "json",
							data: data,
							type: "POST",
							url: $t.data( "suggestions-url" ) + "&" + $.param( {
										q: $t.val(),
										name: $t.attr( "name" ).slice( 0, -13 )
							} ),
							success: function( json ) {
								var childs = $el.find( "input:checkbox:not(:checked)" );
								childs.parent( "li" ).remove();
								$el.children( "ul" ).append( json.items );
							}
					} );
				};
				var $tar = $( ".js--suggestion-multiple input.js--suggestion-source" );
				$tar.change( handler );
				$tar.keyup( handler );
};

