/* global window */
/* global Swiper */
( function( window, $, undefined ) {
	"use strict";
	var document = window.document,
	UTILS = window.UTILS,

	DLMOBILE = window.DLMOBILE,

	DUMLATEK = {
		common: {

			// Application-wide code.
			init: function() {
				UTILS.handleSuggestions();

				// Init MainMenu dropdown functions
				DUMLATEK.utils.initMainMenu();

				// Init offCanvasMenu
				DUMLATEK.utils.initOffCanvas();

				// Form hints.
				$( ".help-hint" ).each( function() {
					var $this = $( this ),
						$field = $this.closest( ".form-group" ).find( ".form-control" ),
						title = $this.data( "title" ) || "",
						content = $this.html(),
						popoverOptions = {
							html: true,
							trigger: "focus",
							title: title,
							content: content
						};

					$field.popover( popoverOptions );
				} );

				$( ".atk14_no_spam" ).unobfuscate( {
					atstring: "[at-sign]",
					dotstring: "[dot-sign]"
				} );

				var rating = $( "#id_rating" );
				if ( rating.length ) {
					rating.rating( {
						min: 1,
						max: 5,
						emptyvalue: 0,
						iconLib: "icon",
						activeIcon: "ion-ios-star active",
						inactiveIcon: "ion-ios-star",
						clearable: false,
						clearableIcon: "ion-android-close",
						clearableRemain: false,
						inline: false,
						readonly: false
					} );
				}

				var $countdownClock = $( ".countdown__clock" ),
					$secondsToStart = $countdownClock.data( "date" ),
					$lang = $countdownClock.data( "lang" ),
					clock = $countdownClock.FlipClock( $secondsToStart, { // jshint ignore:line
						clockFace: "DailyCounter",
						countdown: true,
						language: $lang
					} );

					window.UTILS.searchSuggestion( "js--search", "js--suggesting-area" );
				var tooltipOptions = {
					container: "html"
				};
				$( "[data-toggle='tooltip']" ).tooltip( tooltipOptions );

				// Fake radiobuttony u slucovani objednavek
				if ( $( "input[name='combined_shipping_option_radio']" ).length ) {
					$( "input[name='combined_shipping_option_radio']" ).click( function() {
						var uri = $( this ).closest( "a.option-item" ).attr( "href" );
						window.location.href = uri;
					} );
				}
			}
		},

		main: {

			// Controller-wide code.
			init: function() {
			},

			// Action-specific code
			index: function() {
				DUMLATEK.utils.initSwiper();
			}

		},

		instructions_and_inspirations: {

			// Controller-wide code.
			init: function() {
			},

			// Action-specific code
			index: function() {
				var $masonry = $( ".masonry" );

				$masonry.colcade( {
					columns: ".masonry__col",
					items: ".masonry__item"
				} );
			}

		},

		anniversary_30_years: {

			index: function() {
				var $masonry = $( ".masonry" );

				$masonry.colcade( {
					columns: ".masonry__col",
					items: ".masonry__item"
				} );
			}

		},

		we_joined_forces: {

			index: function() {
				var $masonry = $( ".masonry" );

				$masonry.colcade( {
					columns: ".masonry__col",
					items: ".masonry__item"
				} );
			}

		},

		categories: {

			// Action-specific code
			detail: function() {
				window.ATK14COMMON.filter_init( "#filter_form" );
			}
		},

		new_items: {
			index: function() {
				window.ATK14COMMON.filter_init( "#filter_form" );
			}
		},

		discounts: {
			index: function() {
				window.ATK14COMMON.filter_init( "#filter_form" );
			}
		},

		baskets: {

			// Controller-wide code.
			init: function() {
			},

			// Action-specific code
			edit: function() {

				$( ".js--basket-destroy" ).click( function( e ) {
					var $e = $( e.currentTarget );
					$.ajax( {
						"url": $e.data( "url" ),
						"type": "POST",
						"dataType": "json",
						"success": function( data ) {
										if ( data.emptyBasket ) {
											return location.reload();
										}
										var $table = $e.closest( "table" );
										DUMLATEK.UTIL.update_basket( data, $table );
										var $tr = $e.closest( "tr" );
										$tr.fadeOut( $tr.remove );
										}
					} );
					return false;
				} );

				var handler = function( e ) {
					var $e = $( e.currentTarget );
					if ( e.currentTarget.timeout ) {
						window.clearTimeout( e.currentTarget.timeout );
					}
					e.currentTarget.timeout = window.setTimeout( function() {
						$.ajax( {
							"url": $e.closest( "td" ).data( "url" ),
							"type": "POST",

							// Tady nemuze byt toto:
							// `"data": { amount: parseInt( "0" + $e.val() ) }`
							// protoze se ztrati informace o chybne zadanem cisle
							"data": { amount: $e.val() },

							"dataType": "json",
							"success": function( data ) {
								DUMLATEK.UTIL.update_basket( data, $e.closest( "table" ) );
								var $tr = $e.closest( "tr" );
								$tr.find( ".js--unit_price" ).html( data.snippets.edit.unit_price );
								$tr.find( ".js--price" ).html( data.snippets.edit.price );
							}
						} );
					}, 300 );
				};

				$( ".js--basket-body input" ).on( "input", handler );
				$( ".js--basket-body input" ).on( "change", handler );

			}
		},

		checkouts: {
			set_payment_and_delivery_method: function() {
				DUMLATEK.shipping_rules.checkDependent( {
					determinantName: "delivery_method_id",
					determinedName: "payment_method_id",
					rules: $( "#form_checkouts_set_payment_and_delivery_method" ).data( "rules" )
				} );

				$( "#form_checkouts_set_payment_and_delivery_method :checked" )
					.parents( "li" ).each( function() {
					$( this ).last().addClass( "checked" );
				} );

				$( "#form_checkouts_set_payment_and_delivery_method :radio" ).change( function() {
					if ( $( this ).is( ":checked" ) ) {
						$( this ).closest( ".list--radios" )
							.find( ".checked input[name='group_checkbox']" )
							.attr( "checked", false );
						$( this ).closest( ".list--radios" )
							.find( ".checked" )
							.removeClass( "checked" );
						$( this ).parents( "li" ).last().addClass( "checked" );
					}
				} );
			},

			// Vytvareni objednavky - zadavani dorucivaci a fakturacni adresy
			set_billing_and_delivery_data: function() {

				// Klikani na "Chci zadat fakturační údaje"
				$( "#id_fill_in_invoice_address" ).on( "change", function() {
					var $chbox = $( this ),
						$fields = $( "#invoice-address-fields" );

					if ( $chbox.is( ":checked" ) ) {
						if ( $fields.hasClass( "nojs-only" ) ) {
							$fields.hide();
							$fields.removeClass( "nojs-only" );
						}
						$fields.fadeIn();
					} else {
						$fields.fadeOut();
					}
				} );

				// Vyber dorucovacich adres
				$( ".js--predefined-address" ).click( function() {
					var data = $( this ).data( "json" ),
						$card = $( this ).closest( ".js--card-address" ),
						$cards = $( ".js--card-address" );

					$cards.removeClass( "card--active" );
					$card.addClass( "card--active" );

					$( "#form_checkouts_set_billing_and_delivery_data input, " +
						 "#form_checkouts_set_billing_and_delivery_data select" ).each( function() {
							var name = this.name;
							var $input = $( this );
							var origColor = $input.css( "color" );
							var backgroundColor = $input.css( "background-color" );

							if ( name.substr( 0, 9 ) === "delivery_" ) {
								name = name.substr( 9 );
							} else if ( name !== "phone" ) {
								return;
							}
							if ( data[ name ] !== undefined ) {
								this.value = data[ name ];
								$input.css( "color", backgroundColor );
								$input.animate( {
									color: origColor
								} );
							}
					} );
				} );
			}
		},

		cards: {

			// Action-specific code
			detail: function() {

				// Init Carousel
				DUMLATEK.utils.initSwiper();

				// Zoom na obrazku (pouze na vetsich zarizenich)
				if ( !DLMOBILE.isSmallDevice() ) {
					var $pdetailImageMain = $( ".js--pdetail__image--main" ),
						$bigImageUrl = $pdetailImageMain.children( "a" ).attr( "href" );
					DUMLATEK.utils.initImageZoom( $pdetailImageMain, $bigImageUrl );
				}
				DUMLATEK.utils.initImageHoverChange();

				// Init PhotoSwipe
				UTILS.initPhotoSwipeFromDOM( ".js--pdetail__gallery" );

				// Tabs
				$( "#js--products_to_basket_tabs a" ).click( function( e ) {
					e.preventDefault();
					$( this ).tab( "show" );
				} );

				var $amount = $( ".js--order-quantity-input" );
				/*$amount.change( function( e )
					var value = parseInt( e.target.value );
				*/

				$amount.on( "input change", function( e ) {

					var $el = $( this ).closest( "form" ).find( ".js--order-price" );
					var prices = $el.data( "prices" );
					var price = null;

					var value = parseInt( e.target.value );

					var error = false;
					if ( value < parseInt( e.target.min ) ) {
						error = true;
					} else if ( e.target.max && value > parseInt( e.target.max ) ) {
						error = true;
					}

					/*X
					 * var step = parseInt( e.target.step );
					 * value = Math.ceil( value / step ) * step;*/

					for ( var i = 0; i < prices.prices.length; i++ ) {
						if ( parseFloat( prices.prices[ i ].minimum_quantity ) <= value ) {
							price = parseFloat( prices.prices[ i ].price );
							break;
						}
					}
					if ( price === null ) {
						error = true;
					}
					if ( prices.discount_percent ) {
						price = price - ( price / 100.0 ) * prices.discount_percent;
					}
					/**
					 * @note @google_merchants
					 * Pro aktualizaci ceny vyhledavame postupne elementy
					 * s tridou price a ordered_amount, k dispozici je jeden z nich.
					 * Vysvetleni je v sablonce cards/_products_to_basket_item.tpl.
					 */
					if ( !error ) {
						price = price * ( 1 + prices.vat_percent / 100.0 ) * value;
						price = price / prices.currency_rate;
						price = price.toFixed( $el.data( "decimals" ) ).replace( ".", "," );
						$( $el.find( ".price" ) ).html( price );
						$( $el.find( ".ordered_amount" ) ).html( price );
					} else {
						$( $el.find( ".price" ) ).html( "?" );
						$( $el.find( ".ordered_amount" ) ).html( "?" );
					}

				} );

				// Prihozeni produktu do kosiku
				var $addToBasketForm = $( ".js--add_to_basket_form" );
				$addToBasketForm.on( "ajax:success", function( e, data ) {
					window.bootbox.dialog( {
						message: data.modal,
						backdrop: true,
						onEscape: true
					} );
					$( "#basketinfo" ).replaceWith( data.basketinfo );

					if (
						typeof data.datalayer !== "undefined" &&
						typeof window.dataLayer !== "undefined" ) {
						$.each( data.datalayer, function( idx, obj ) {
							window.dataLayer.push( obj );
						} );
					}
				} );

				// Prihozeni produktu do mixeru
				$( "#add_to_mixer" ).on( "ajax:success", function( ) {
					var dialog = window.bootbox.dialog( {
						message: "<div id='mixeer'></div>",
						className: "modal--mixer",
						size: "large",
						backdrop: true,
						onEscape: true
					} );

					// Inicializace drag'n'drop a resize
					dialog.init( function() {
						console.log( "bootbox.dialog.init" );

						// DUMLATEK.utils.initMixer();
					} );

					dialog.on( "shown.bs.modal", function() {

						console.log( "shown.bs.modal" );
						DUMLATEK.utils.initMixer();
					} );

					$( "#mixeer" )[ 0 ].dialog = dialog;
				} );

			}

		},

		logins: {
			create_new: function() {
				$( "#id_login" ).focus();
			}
		},

		users: {

			// Controller-wide code.
			init: function() {
			},

			// Action-specific code
			create_new: function() {
				/*
				 * Check whether login is available.
				 * Simple demo of working with an API.
				 */
				var $login = $( "#id_login" ),
					m = "Username is already taken.",
					h = "<p class='alert alert-danger col-sm-4 col-sm-offset-2'>" + m + "</p>",
					$status = $( h ).hide().appendTo( $login.closest( ".form-group" ) );

				$login.on( "change", function() {

					// Login input value to check.
					var value = $login.val(),
						lang = $( "html" ).attr( "lang" ),

					// API URL.
						url = "/api/" + lang + "/login_availabilities/detail/",

					// GET values for API. Available formats: xml, json, yaml, jsonp.
						data = {
							login: value,
							format: "json"
						};

					// AJAX request to the API.
					if ( value !== "" ) {
						$.ajax( {
							dataType: "json",
							url: url,
							data: data,
							success: function( json ) {
								if ( json.status !== "available" ) {
									$status.fadeIn();
								} else {
									$status.fadeOut();
								}
							}
						} );
					}
				} ).change();
			}
		},

		styleguides: {

			// Controller-wide code.
			init: function() {
			},

			// Action-specific code
			typography: function() {
				DUMLATEK.utils.fontTest();
			}
		},

		tests: {
			extended_password_field: function() {
				UTILS.extended_password_field.init();
			}
		},

		utils: {
			initMixer: function() {
				$( ".js--mixercard" ).draggable( {
					containment: "#js--mixercanvas",
					scroll: false,
					cursor: "move",
					stack: ".js--mixercard",
					distance: 0,
					handle: ".js--mixercardimage"
				} ).find( ".js--mixercardimage" ).resizable( {
					containment: "#js--mixercanvas",
					maxHeight: 600,
					maxWidth: 600,
					minHeight: 200,
					minWidth: 200
				} );
			},

			/* Puvodni carousel
			initCarousel: function( carouselObject, carouselItemsCount ) {
				carouselObject.owlCarousel( {
					loop:true,
					responsiveClass:true,
					stagePadding:5,
					dots: false,
					responsive:{
						0:{
							items:1,
							nav:true
						},
						600:{
							items:3,
							nav:false
						},
						1000:{
							items:carouselItemsCount,
							nav:true,
							loop:false
						}
					}
				} );
			}, */

			initSwiper: function() {
				$( ".swiper-container" ).each( function( index, container ) {
					var $container = $( container );
					var slidesPerView = $container.data( "slides_per_view" ); // 4 or 5
					var breakpoints = {
						976: {
							slidesPerView: 3
						},
						600: {
							slidesPerView: 1
						}
					};
					if ( slidesPerView === 6 ) {
						breakpoints = {
							1300: {
								slidesPerView: 5
							},
							976: {
								slidesPerView: 3
							},
							600: {
								slidesPerView: 1
							}
						};
					} else if ( slidesPerView === 5 ) {
						breakpoints = {
							1300: {
								slidesPerView: 4
							},
							976: {
								slidesPerView: 3
							},
							600: {
								slidesPerView: 1
							}
						};
					}

					new Swiper( container, {
						slidesPerView: slidesPerView,
						spaceBetween: -1,
						watchOverflow: true,
						roundLengths: true,
						navigation: {
							nextEl: $container.data( "next_el" ),
							prevEl: $container.data( "prev_el" ),
							disabledClass: "swiper-button--disabled",
							hiddenClass: "swiper-button--hidden"
						},
						breakpoints: breakpoints
					} );
				} );
			},

			activateSubmenu: function( row ) {
				var $row = $( row ),
					$rowLink = $row.children( "a" ),
					$navItemOpen = $( ".nav__item--open" ),
					$submenu = $row.children( ".js--nav__panel" );

				// Logovani
				// console.dir( $submenu );
				console.log( "activateSubmenu" + $submenu );

				// Odstran open class ze vsech $navItemOpen
				// Zakomentovano: funguje, ale jen na dotek
				$navItemOpen.removeClass( "nav__item--open" );
				$navItemOpen.find( ".nav__panel--open" ).removeClass( "nav__panel--open" );

				// Add class
				$row.addClass( "nav__item--open" );

				// Show the submenu
				$submenu.addClass( "nav__panel--open" );

			  // Set focus na link
				$rowLink.focus();

				$rowLink[ 0 ].opened_at = ( new Date() ).getTime();

				// // Keep the currently activated row's highlighted look
				// $row.find( "a" ).addClass( "maintainHover" );
			},

			fontTest: function() {
				var testString = "TTT",
						minSize = 8,
						maxSize = 40,
						weights = [ 200, 300, 400, 600, 700, 900 ];

				for ( var i = minSize; i <= maxSize; i++ ) {
					$( "<div class='t t" + i + "'>" + i + "px </div>" )
					.appendTo( ".js--font-test" )
					.css( { "font-size": i + "px" } );

					for ( var j = 0; j < weights.length; j++ ) {
						console.log( "loop2: " + weights[ j ] );
						var id = "t" + i,
							div = $( "." + id ),
							span1 = "<span class='text'>" + testString + "</span>",
							span2 = "<span class='text text--i'>" + testString + "</span>",
							spans = span1 + span2;
						$( spans ).appendTo( div ).css( { "font-weight": weights[ j ] } );
					}
				}
			},

			initMainMenu: function() {

				// Main navigation

				var $mainNav = $( "#js--nav-level-1" ),
					$body = $( "body" ),
					$dropdown = $( ".js--nav__dropdown" ),
					$dropdownToggle = $( ".js--dropdownToggle" ),
					$dropdownMenu = $( ".js--dropdownMenu" ),
					$navItemLink = $( ".js--nav__item > a" ),
					$firstItem = $( "#js--nav-level-1 .js--nav__item:first-child" );

				// Zabranit linku na a hrefu
				$navItemLink.on( "click", function( e ) {
					var diff = ( new Date() ).getTime() - e.target.opened_at;
					if ( diff < 50 ) {
						e.preventDefault();
						return false;
					}
				} );

				$dropdownToggle
				.attr( "aria-haspopup", "true" )
				.attr( "aria-expanded", "false" )
				.dropdownHover( {
					delay: 500,
					instantlyCloseOthers: false,
					hoverDelay: 100
				} ).dropdown();

				// Dropdown se nezavre kdyz, je uz otevreny klikne se do nej
				$dropdownMenu.on( "click", function( e ) {
					e.stopPropagation();
				} );

				$dropdown.on( "show.bs.dropdown", function() {

					// Pridej overlay pres stranku
					$body.addClass( "navActive" );

					// Otevrit prvni polozku v dropdownu
					// pouze pokud neni otevrena
					if ( !$dropdown.hasClass( "js--nav__dropdown--open" ) ) {
						$dropdown.addClass( "js--nav__dropdown--open" );

						// Aktivuj prvni polozku
						DUMLATEK.utils.activateSubmenu( $firstItem );
					}

					$( ".js--nav__dropdown--open" ).on( "click", function() {

						// Console.log( "test" );
						// $( this ).off();
						// $( this ).children( "a" ).off();
					} );

				} );

				$dropdown.on( "hide.bs.dropdown", function() {

					// Odeber overlay pres stranku
					$body.removeClass( "navActive" );
					$dropdown.removeClass( "js--nav__dropdown--open" );

				} );

				$mainNav.menuAim( {

					// A means of activating submenu.
					// It's either 'hover' or 'click' or 'both
					triggerEvent:       "hover",

					// Selector for identifying which elements
					// in the menu are rows
					rowSelector:        ".js--nav__item",

					// Handle for triggering mouse clicks on menu item
					handle:             "> a",

					// You may have some menu rows that aren't submenus
					// and thereforeshouldn't ever need to "activate."
					// If so, filter submenu rows w/
					// this selector. Defaults to "*" (all elements).
					submenuSelector:    "*",

					// Direction the submenu opens relative to the
					// main menu. Can be left, right, above, or below.
					// Defaults to "right".
					submenuDirection:   "right",

					// Class that will be applied to menu item
					// when activated
					openClassName:      "nav__item--open",

					// Bigger = more tolerant for mouse movement
					// precision when entering submenu
					tolerance:          900,

					// Delay (ms) for first submenu opening
					activationDelay:    0, // Yarri: Tady bylo 200

					// Number of past mouse locations to track direction
					mouseLocsTracked:   3,

					// Delay (ms) when user appears to be entering
					// submenu and mouse movement is being tracked
					defaultDelay:       300,

					// Function to call when mouse enters a menu row.
					// Entering a row does not mean the row has been
					// activated, as the user may be
					// mousing over to a submenu.
					// enterCallback: function() {
					// 	console.log( "enter" );
					// },

					// Function to call when a row is purposefully
					// activated. Use this to show a submenu's
					// content for the activated row.
					activateCallback: DUMLATEK.utils.activateSubmenu,

					// Function to call when a row is deactivated.
					deactivateCallback: DUMLATEK.utils.deactivateSubmenu,

					// Function to call when mouse exits a menu row.
					// exitCallback: function() {
					// 	return true;
					// },

					// Function to call when mouse exits whole menu.
					// This is needed for autoclosing submenu
					exitMenuCallback:   $.noop

				} );
			},

			initOffCanvas: function() {
				var $offcanvasToggle = $( ".js--offcanvasToggle" ),
				$pageDrawer = $( "#js--page__drawer" ),
				$layerCloseButton = $( ".js--offcanvas-layer-close" ),
				$layerOpenButton = $( ".js--offcanvas-layer-open" );

				// Pokud trasition na pageDroawer probehla odeber ji
				// Bude tak nastavena jen pri otevirani OffCanvas Menu
				$pageDrawer.on( "transitionend webkitTransitionEnd oTransitionEnd", function( ) {
					$pageDrawer.css( { transition: "none" } );
				} );

				// Otevirani mobilniho OffCanvas Menu
				// + pridani transition: az ted, aby se jinak pageDrawer neanimoval
				// napr. pri zmene veliksoti okna
				$offcanvasToggle.on( "click", function() {
					$pageDrawer
					.css( { transition: "transform .3s ease-in-out" } )
					.toggleClass( "active" );
				} );

				// Zavira vrstvu - nyni pouze pro menu level-1
				$layerCloseButton.on( "click", function() {
					$( this )
					.closest( ".js--offcanvas__layer" )
					.addClass( "offcanvas__layer--hidden" );
				} );

				// Otvira vrstvu - nyni pouze pro menu level-1
				$layerOpenButton.on( "click", function( e ) {
					var $layername = $( this ).data( "open-layer" ),
						$layer = $( $layername );

					$layer.removeClass( "offcanvas__layer--hidden" );

					// At nefunguje proklik
					e.preventDefault();
				} );

				// Docasne zobraz offcanvas vzdy
				// $pageDrawer.toggleClass( "active" );
			},

			initImageZoom: function( imageBase, bigImageUrl ) {

				var $pdetailImageMain = imageBase,
					$bigImageUrl = bigImageUrl;

				$pdetailImageMain.zoom( {
					url: $bigImageUrl
				} );

			},

			initImageHoverChange: function() {
				var $mainImages = $( ".js--pdetail__image--main" ),
					$tbnsList = ( ".js--gallery-tbns" );

				$mainImages
				.clone()
				.appendTo( $tbnsList )
				.removeClass( "js--pdetail__image--main" )
				.addClass( "js--pdetail__image--tbn" )
				.removeClass( "pdetail__image--main" )
				.addClass( "pdetail__image--tbn" );

				$( ".js--pdetail__image--tbn" ).on( {
					"mouseenter": function() {
						if ( !$( this ).hasClass( "pdetail__image--active" ) ) {
							var $tbns = $( ".js--pdetail__image--tbn" ),
								$tbnIndex = $tbns.index( this ),
								$mainImgToActivate = $( $mainImages.get( $tbnIndex ) ),
								$bigImgUrl = $mainImgToActivate.children( "a" ).attr( "href" );
							console.log( "Index" + $tbnIndex );
							console.log(
								"$mainImgToActivate" +
								$mainImgToActivate.attr( "class" )
							);
							$tbns.removeClass( "pdetail__image--active" );
							$( this ).addClass( "pdetail__image--active" );
							$mainImages.removeClass( "pdetail__image--active" );
							$mainImgToActivate.addClass( "pdetail__image--active" );

							// Zoom na obrazku
							DUMLATEK.utils.initImageZoom( $mainImgToActivate, $bigImgUrl );
						}
					}
				} );
			}
		}
	};

	/*
	 * Garber-Irish DOM-based routing.
	 * See: http://goo.gl/z9dmd
	 */
	DUMLATEK.UTIL = {
		exec: function( controller, action ) {
			var ns = DUMLATEK,
				c = controller,
				a = action;

			if ( a === undefined ) {
				a = "init";
			}

			if ( c !== "" && ns[ c ] && typeof ns[ c ][ a ] === "function" ) {
				ns[ c ][ a ]();
			}
		},

		init: function() {
			var body = document.body,
			controller = body.getAttribute( "data-controller" ),
			action = body.getAttribute( "data-action" );

			DUMLATEK.UTIL.exec( "common" );
			DUMLATEK.UTIL.exec( controller );
			DUMLATEK.UTIL.exec( controller, action );
		},

		update_basket: function( data, table ) {
			var $table = $( table );
			$( "#error_messages" ).replaceWith( data.snippets.edit.error_messages );
			$( "#add_more_to_get_free_delivery_message" ).
				replaceWith( data.snippets.edit.add_more_to_get_free_delivery_message );
			$table.find( "tfoot" ).replaceWith( data.snippets.edit.tfoot );
			$( "#basketinfo" ).replaceWith( data.snippets.basketinfo );
		}
	};

	// Expose DUMLATEK to the global object.
	window.DUMLATEK = DUMLATEK;

	// Initialize application.
	$().ready( DUMLATEK.UTIL.init );
} )( window, window.jQuery );
